import { ComponentProps } from 'react';

import {
    HeroImage,
    HeroImageData,
    OFFSET_STRENGTH,
} from '@/components/hero-image';
import { ParallaxLayer } from '@/components/parallax/components/parallax-layer';
import { cn } from '@/utils/cn';

export const ParallaxImages = ({
    back,
    className,
    images,
    ...props
}: {
    back?: boolean;
    images: HeroImageData[];
} & ComponentProps<typeof ParallaxLayer>) => (
    <ParallaxLayer
        className={cn(`absolute inset-0 h-lvh w-full`, className)}
        parallax={back ? '75lvh' : '150lvh'}
        {...props}
    >
        {images.map((image, index) => (
            <HeroImage
                delay={index * 300 + 1500}
                key={image.alt}
                shaded={back}
                pointerOffsetStrength={
                    back ? OFFSET_STRENGTH.LOW : OFFSET_STRENGTH.DEFAULT
                }
                {...image}
            />
        ))}
    </ParallaxLayer>
);
